/* 768++  */
.b-how-pass {
    padding-bottom: 60px;
    padding-top: 80px;

    &__panel-top,
    &__info,
    &__image {
        display: none;
    }

    &__tab-content,
    &__panel {
        width: 100%;
    }

    &__map {
        height: 300px;

        &--height {
            height: 300px;
        }
    }

    &__label {
        font-weight: 300;
        font-size: 30px;
        line-height: 30px;
        letter-spacing: 0;
        text-align: center;
        width: 100%;
        color: #e73a74;

        span {
            font-weight: 700;
        }
    }
}