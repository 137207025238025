/* 768++  */
.b-header {
    padding: 15px 0;

    .b-container {
        justify-content: space-between;
    }

    &__logo {
        margin-right: 0;

        .icon-home {
            display: none;
        }
        .icon-logo-chef {
            display: block;
        }
    }

    &__button {
        display: none;
    }

    &__close {
        display: block;
    }

    &__menu {
        width: calc(~"100vw - 40px");
        top: 24px;
        left: 20px;
        z-index: 120;
        position: fixed;
        display: none;

        &.active {
            display: block;
        }
    }

    &__list {
        padding: 20px 10px 0;
        background-color: rgba(0,0,0,.8);
        border-radius: 4px 0 4px 4px;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__item {
        padding: 0 10px;
        margin-bottom: 20px;

        &:before {
            display: none;
        }

        &:first-child {
            display: none;
        }
    }

    &__link {
        width: 125px;
        height: 125px;
        border: 1px solid #fff;
        border-radius: 3px;
        line-height: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
    }

    &__icon {
        display: flex;
    }

    &__phone {
        display: none;
    }

    &__menu-button {
        display: flex;
        padding: 0;

        .no-touchevents &:hover,
        .touchevents &:active {
            padding: 0;
        }

        &.active {
            opacity: 0;
        }
    }
}