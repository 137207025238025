/* 768++  */
.b-info {
	&__list {
		background-image: none;
		flex-direction: column;
	}

	&__item {
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		text-align: left;
	}

	&__date {
		display: none;
	}

	&__caption {
		display: none;
	}

	&__icon {
		width: 90px;
		flex-shrink: 0;

		img {
			width: 100%;
		}
	}

	&__panel {
		padding-left: 40px;
	}

	&__text {
		br {
			display: none;
		}
	}
}