/* 768++  */
.b-footer {
    margin-top: 50px;
    
    .b-container {
        padding: 0 20px;
    }

    &__list {
        display: none;
    }

    &__copyright {
        font-size: 12px;
    }

    &__logo {
        margin-right: 20px;
    }
}