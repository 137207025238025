/* 768++  */
.b-about {
    background-image: none;
    background-color: #000;
    padding: 40px 0;

    &:before {
        display: none;
    }

    &__banner {
        display: none;
    }

    &__panel {
        padding: 0;
    }

    &__image {
        display: none;
    }

    &__info {
        color: #fff;
    }

    &__link {
        padding-bottom: 0;
        text-align: center;

        a {
            color: #fff;
        }
    }

    &__list {
        width: 650px;
        margin: 0 auto 40px;
    }

    &__group-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &__button {
        .text {
            width: 192px;
            height: 56px;
            font-size: 14px;
            line-height: 56px;
        }


        &--download {
            display: flex;
        }

        .icon-download,
        .icon-tickets {
            font-size: 50px;
            margin-right: 15px;
        }
    }

    &__wrapper {
        margin-bottom: 40px;
    }
}