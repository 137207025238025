/* 768++  */
.b-media {
	padding-top: 50px;

	&__panel-top {
		text-align: center;
		padding: 0;
		margin-bottom: 40px;
	}

	&__navigation {
		display: none;
	}

	&__info {
		display: none;
	}

	&__back {
		display: none;
	}

	&__caption {
		display: none;
	}

	&__title {
		font-weight: 300;
		font-size: 30px;
		line-height: 30px;
		letter-spacing: 0;
		text-align: center;
		width: 100%;

		span {
			font-weight: 700;
		}
	}

	&__tab-list {
		justify-content: center;
	}

	&__tab-link {
		font-size: 26px;
		height: 55px;
		line-height: 55px;
		border-radius: 5px;
		padding: 0 15px;
	}

	&__item {
		width: calc(~"100 / 3");
	}

	&__link {
		border-radius: 5px;
		height: 143px;
	}

	&__more {
		font-size: 14px;
		line-height: 16px;
	}
}