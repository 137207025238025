/* 768++  */
.b-program {
    padding-top: 40px;
    margin-bottom: 40px;

    &__head {
        flex-direction: column;
        font-size: 24px;
        letter-spacing: 7.2px;
        margin-bottom: 34px;
    }

    &__caption {
        font-size: 24px;
        line-height: 26px;
        letter-spacing: 1.2px;
        padding: 26px 0 0;
        text-align: center;

        &:before {
            display: none;
        }
    }

    &__list {
        margin: 0 -20px;
        overflow: hidden;
    }

    &__item {
        width: 50%;
        display: flex;
        flex-direction: column;
        height: 181px;
        display: flex;
        padding: 0;
        position: relative;

        &:last-child {
            width: 100%;
            border-top: none;
            height: auto;
            padding-top: 36px;
        }

        &:nth-child(3n) {
            border-right: 1px solid #e5e5e5;
        }

        &:nth-child(-n+3) {
            border-top: 1px solid #e5e5e5;
        }

        &:nth-child(even) {
            border-right: none;

            .b-program__detail {
                padding-left: 10px;
                transform: translateX(-220%);

                &.active {
                    transform: translateX(-98%);
                }
            }
        }

        &:nth-child(odd) {
            .b-program__detail {
                transform: translateX(220%);

                &.active {
                    transform: translateX(100%);
                }
            }
        }
    }

    &__image {
        margin-bottom: 4px;

        img {
            height: 72px;
        }
    }

    &__title {
        font-size: 19px;
        line-height: 21px;
    }

    &__info {
        cursor: pointer;
        padding-top: 35px;
        padding: 35px 0 0;
        height: 100%;
    }

    &__detail {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        transition: transform 0.3s;
        background-color: #fff;
        height: calc(~"100% - 1px");
        margin-left: -2px;
        width: calc(~"100% + 1px");
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__text {
        font-size: 13px;
        line-height: 16px;
        text-align: left;
        margin-bottom: 11px;
    }

    &__link {
        width: 100%;
        max-width: 320px;
        font-size: 16px;
        height: 56px;
        line-height: 18px;

        .icon-list {
            font-size: 31px;
            top: 2px;
        }
    }

    &__button {
        margin-left: 0;
        width: 106px;
        height: 28px;
        line-height: 26px;
        font-size: 12px;
        letter-spacing: 0.3px;
    }

    &__close {
        display: block;
    }
}