/* 768++  */
.b-expert {
    &__wrapper {
        padding-left: 0;
        padding-right: 0;
    }

    &__group {
        flex-direction: column;
    }

    &__image {
        width: 100%;
        height: 240px;
        margin-bottom: 20px;

        img {
            max-height: 100%;
            margin: 0 auto;
        }
    }

    &__description {
        width: 100%;
        padding: 0;
    }

    &__name {
        text-align: center;
        margin: 0 auto 30px;
        max-width: calc(~"100% - 130px");
    }

    &__title {
        font-size: 22px;
        line-height: 25px;
        letter-spacing: 7px;
        top: 0;
        left: 0;
        padding-left: 0;
    }

    &__button-group {
        bottom: auto;
        top: 250px;
        padding-left: 0;
        width: 100%;
    }

    &__panel {
        margin-bottom: 40px;
    }

    &__button {
        margin: 0 auto;
    }

    &__arrow-group {
        justify-content: space-between;
        width: 100%;
    }
}