/* 768++  */
.b-platform {
	&__link {
		width: 80px;
		height: 110px;
	}

	&__icon {
		width: 70px;
		height: 70px;

		span {
			font-size: 50px;

			&.icon-platform-11 {
    			font-size: 35px;

			}
		}
	}
}